































import Bootstrapper from '@/app_code/Bootstrapping/Bootstrapper'
import { GetBlogModel } from '@/app_code/models/GetBlogModel'
import { API, ServiceManager } from '@/app_code/services'
import { AsyncView } from '@/app_code/services/meta/view'
import ServiceType from '@/app_code/services/ServiceTypes'
import { Mixins, Component } from 'vue-property-decorator'
import Topic from '../components/Topic.vue'
import Loader from '../components/ui/Loader.vue'

@Component({
  components: {
    Topic,
    Loader
  }
})
export default class BlogDetails extends Mixins(AsyncView) {
  private blog: GetBlogModel | null = null

  private readonly blogId = this.$route.params.id

  private api = ServiceManager.Require<API>(ServiceType.API)

  mounted (): void {
    this.getBlogPost()
  }

  private getBlogPost (): Promise<boolean> {
    return new Promise((resolve, reject) =>
      this.api.BlogAPI.GetBlog(this.blogId)
        .then(data => {
          this.blog = data
          console.log(this.blog)
          resolve(true)
        })
        .catch(reject)
    )
  }

  private getFileUrl (url: string): string {
    return `${Bootstrapper.Config?.API.Base}${url}`
  }
}
